import * as React from "react";
import { useState } from "react";
import { GenericField, InputProps } from "../Fields/GenericField";
import { CheckIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { SelectOption } from "@/lib/Components/Form/Components/Select";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronsUpDown } from "lucide-react";
import { useField } from "formik";

export type OptionGroup<T = any> = {
  label: string;
  options: SelectOption<T>[];
};

export type SelectInputProps<T = any> = InputProps & {
  onChange?: (value: T | null) => void;
  clearable?: boolean;
  options: OptionGroup<T>[];
  initialOpen?: boolean;
  disabled?: boolean;
  filter?: (value: string, search: string) => number;
  placeholder?: string;
};

export function SelectInput<T extends string>({
  options,
  onChange,
  initialOpen = false,
  disabled,
  filter,
  clearable,
  placeholder = "Select a value",
  ...props
}: SelectInputProps<T>) {
  const [open, setOpen] = useState(initialOpen);
  const [{ value }, , { setValue }] = useField(props.name);

  const allOptions = options.flatMap((group) => group.options);

  const selected = allOptions.find((framework) => framework.value === value);

  return (
    <GenericField viewNode={<span>{selected?.label ?? "--"}</span>} {...props}>
      <>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              disabled={disabled}
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="w-full min-w-0 border-gray-300 shadow-sm text-sm transition-shadow duration-100 ring-offset-0 h-[38px]"
            >
              <span
                className={cn("flex-grow truncate text-left", {
                  "text-gray-400": !value,
                })}
              >
                {selected?.label ?? placeholder}
              </span>

              <span className="flex-shrink-0 hover:bg-gray-200 ml-2 -mr-3 p-1 rounded-md">
                {clearable && value ? (
                  <XMarkIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(null);
                    }}
                    className="size-3.5 shrink-0 opacity-50"
                  />
                ) : (
                  <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
                )}
              </span>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0" align="start">
            <Command filter={filter}>
              <CommandInput placeholder="Search..." className="h-9" />
              <CommandEmpty>No item found.</CommandEmpty>
              <CommandList>
                <div className="">
                  {options.map((group, index) => {
                    return (
                      <CommandGroup heading={group.label} key={index}>
                        {group.options.map((option) => (
                          <CommandItem
                            key={option.value}
                            value={option.value}
                            onSelect={async () => {
                              await setValue(option.value);
                              onChange?.(option.value);
                              setOpen(false);
                            }}
                          >
                            {option.label}
                            <CheckIcon
                              className={cn(
                                "ml-auto h-4 w-4",
                                value === option.value
                                  ? "opacity-100"
                                  : "opacity-0",
                              )}
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    );
                  })}
                </div>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </>
    </GenericField>
  );
}
