import { ImportTable } from "@/app/Import/Components/ImportTable";
import { useMatch } from "@tanstack/react-router";

export function ImportSpreadsheetScreen() {
  const { params } = useMatch({
    from: "/_app/import/$supplierId",
  });

  return <ImportTable supplierId={params.supplierId} />;
}
