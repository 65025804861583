import { Description, Field, Label } from "@/components/catalyst/fieldset";
import { CustomToolPanelProps } from "ag-grid-react";
import { Select } from "@/components/catalyst/select";
import {
  getQueryKey,
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import {
  importSettingRecord,
  updateImportSettingsMutation,
} from "@/app/Import/GraphQL/updateImportSettingsMutation";
import { Switch, SwitchField } from "@/components/catalyst/switch";
import { Input } from "@/components/catalyst/input";
import { useThrottle } from "@/lib/Utils/useThrottle";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

export function SettingsToolPanel({
  supplierId,
}: CustomToolPanelProps<any, any, any> & {
  supplierId: string;
}) {
  const { data: importSettingRes } = useSuspenseGqlQuery(importSettingRecord, {
    supplierId,
  });

  const [ignoreColumns, setIgnoreColumns] = useState<string>(
    importSettingRes.importSetting?.ignore_columns ?? "",
  );
  const throttledValue = useThrottle(ignoreColumns, 1000);
  const queryClient = useQueryClient();

  const { mutate } = useGqlMutation(updateImportSettingsMutation, {
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: getQueryKey(importSettingRecord, { supplierId }),
      });
    },
  });

  useEffect(() => {
    mutate({
      input: {
        supplier_id: supplierId,
        ignore_columns: throttledValue,
      },
    });
  }, [throttledValue]);

  return (
    <div className="space-y-12 p-3">
      <div className="space-y-6">
        <h2 className="text-base font-semibold text-gray-900">
          Paste Settings
        </h2>
        <Field>
          <Label>Date format</Label>
          <Select
            name="date_format"
            defaultValue={importSettingRes?.importSetting?.date_format}
            onChange={(e) => {
              mutate({
                input: {
                  supplier_id: supplierId,
                  date_format: e.target.value,
                },
              });
            }}
          >
            <option value="DD/MM/YYYY">07/022/2024 - DD/MM/YYYY</option>
            <option value="D/M/YYYY">7/2/2024 - D/M/YYYY</option>
            <option value="MM/DD/YYYY">12/07/2024 - MM/DD/YYYY</option>
            <option value="M/D/YYYY">2/7/2024 - M/D/YYYY</option>
            <option value="YYYY-MM-DD">2024-12-07 - YYYY-MM-DD</option>
          </Select>
        </Field>
      </div>

      <div className="space-y-6">
        <h2 className="text-base font-semibold text-gray-900">
          Spreadsheet Settings
        </h2>
        <SwitchField>
          <Label>Ignore Heading</Label>
          <Description>
            Ignore the first line when importing a spreadsheet
          </Description>
          <Switch
            onChange={(checked) => {
              mutate({
                input: {
                  supplier_id: supplierId,
                  ignore_header_row: checked,
                },
              });
            }}
            name="ignore_header_row"
            defaultChecked={importSettingRes?.importSetting?.ignore_header_row}
          />
        </SwitchField>

        <SwitchField>
          <Label>Ignore Missing Rego</Label>
          <Description>
            Ignore any rows that are missing a line/rego no.
          </Description>
          <Switch
            onChange={(checked) => {
              mutate({
                input: {
                  supplier_id: supplierId,
                  ignore_missing_line: checked,
                },
              });
            }}
            name="ignore_missing_line"
            defaultChecked={
              importSettingRes?.importSetting?.ignore_missing_line
            }
          />
        </SwitchField>

        <Field>
          <Label>Ignore Columns</Label>
          <Description>
            Ignore specific columns when importing from a spreadsheet
          </Description>
          <Input
            placeholder="e.g. A,B,C"
            name="ignore_columns"
            defaultValue={
              importSettingRes?.importSetting?.ignore_columns ?? undefined
            }
            onChange={(e) => {
              setIgnoreColumns(e.target.value);
            }}
          />
        </Field>
      </div>
    </div>
  );
}
