import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { OfficeCreateDialog } from "@/app/Offices/Components/OfficeCreateDialog";
import { SupplierTripsTable } from "@/app/Suppliers/Components/SupplierTripsTable";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { OfficeTable } from "@/app/Offices/Components/OfficeTable";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";

export function SupplierOfficeScreen() {
  const record = useSupplierRecord();
  const { open } = useDialog(OfficeCreateDialog);

  return (
    <>
      <OfficeTable
        id={"supplier-offices"}
        queryVariables={{
          supplier_id: record.id,
          archived: false,
        }}
        rightButtons={
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({
                initialValues: {
                  supplier: {
                    connect: record.id,
                  },
                },
              });
            }}
          >
            Add office
          </Button>
        }
      />

      <RecordScreenCard title="Trips">
        <SupplierTripsTable id="supplier-offices" supplierId={record.id} />
      </RecordScreenCard>
    </>
  );
}
