import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandList,
} from "@/components/ui/command";
import {
  CheckCircle2,
  Delete,
  Loader2,
  MapPin,
  Pencil,
  XCircle,
} from "lucide-react";
import React, { PropsWithChildren, useCallback, useState } from "react";

import { Command as CommandPrimitive } from "cmdk";
import { cn } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useThrottle } from "@/lib/Utils/useThrottle";
import { useFetchData, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  googleMapsAddressLookupQuery,
  googleMapsPlaceDetailsQuery,
} from "@/app/Address/GraphQL/googleMapsAddressLookupQuery";
import { useAuth } from "@clerk/clerk-react";
import {
  GenericField,
  InputProps,
} from "@/lib/Components/Form/Fields/GenericField";
import { useField } from "formik";
import { CreateAddressInput } from "@/gql/graphql";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { Map } from "@/lib/GoogleMaps/Components/Map";

type AddressAutoCompleteProps = InputProps & {};

export function AddressInput({
  name,
  label,
  className,
}: AddressAutoCompleteProps) {
  const [searchInput, setSearchInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [{ value }, {}, { setValue }] = useField<CreateAddressInput>(name);

  return (
    <GenericField
      viewNode={value.name}
      name={name}
      className={className}
      label={label}
    >
      {value.name ? (
        <div className="flex items-center gap-2">
          <input
            value={value?.name}
            readOnly
            className="block w-full rounded-md border-gray-300 text-sm shadow-sm transition-shadow duration-100 focus:border-indigo-500 focus:ring-indigo-500 truncate"
          />

          <AddressDialog
            name={name}
            isLoading={isFetching}
            dialogTitle={"Edit Address"}
            address={value}
            setAddress={setValue}
            open={isOpen}
            setOpen={setIsOpen}
          >
            <Button
              disabled={isFetching}
              size="icon"
              variant="outline"
              className="shrink-0"
            >
              <Pencil className="size-4" />
            </Button>
          </AddressDialog>
          <Button
            type="reset"
            onClick={() => {
              setValue({
                name: "",
                place_id: "",
                address_line_1: "",
                address_line_2: "",
                city: "",
                postcode: "",
                country: "",
                state: "",
                lat: 0,
                lng: 0,
              });
            }}
            size="icon"
            variant="outline"
            className="shrink-0"
          >
            <Delete className="size-4" />
          </Button>
        </div>
      ) : (
        <AddressAutoCompleteInput
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setIsOpenDialog={setIsOpen}
          setIsFetching={setIsFetching}
          setAddress={setValue}
        />
      )}
    </GenericField>
  );
}

type CommonProps = {
  setIsOpenDialog: (isOpen: boolean) => void;
  showInlineError?: boolean;
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  placeholder?: string;
  setIsFetching?: (isFetching: boolean) => void;
  setAddress?: (address: CreateAddressInput) => void;
};

function AddressAutoCompleteInput({
  setIsOpenDialog,
  showInlineError,
  searchInput,
  setSearchInput,
  placeholder,
  setIsFetching,
  setAddress,
}: CommonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { sessionId } = useAuth();

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const fetchPlaceDetails = useFetchData(googleMapsPlaceDetailsQuery);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      close();
    }
  };

  const debouncedSearchInput: string = useThrottle(searchInput, 500);

  const { data, isFetching } = useGqlQuery(
    googleMapsAddressLookupQuery,
    {
      input: {
        search: debouncedSearchInput,
        sessiontoken: sessionId,
      },
    },
    {
      placeholderData: (previousData: any) => previousData,
      enabled: debouncedSearchInput.length > 0,
    },
  );

  const predictions = data?.googleMapsAutocomplete || [];

  return (
    <Command
      shouldFilter={false}
      onKeyDown={handleKeyDown}
      className="overflow-visible"
    >
      <div className="flex w-full items-center justify-between bg-background text-sm border-gray-300 border focus-within:border-indigo-500 focus-within:ring-indigo-500 rounded-md shadow-sm transition-shadow duration-100">
        <MapPin className="size-4 ml-2" />
        <CommandPrimitive.Input
          value={searchInput}
          onValueChange={setSearchInput}
          onBlur={close}
          onFocus={open}
          placeholder={placeholder || "Enter address"}
          className="block text-sm w-full border-0 focus:outline-0 focus:ring-0 rounded-md"
        />
      </div>
      {searchInput !== "" && !isOpen && showInlineError && (
        <FormMessages
          type="error"
          className="pt-1 text-sm"
          messages={["Choose a valid address from the list"]}
        />
      )}

      {isOpen && (
        <div className="relative animate-in fade-in-0 zoom-in-95 h-auto">
          <CommandList>
            <div className="absolute top-1.5 z-50 w-full">
              <CommandGroup className="relative h-auto z-50 min-w-[8rem] overflow-hidden rounded-md border shadow-md bg-background">
                {isFetching ? (
                  <div className="h-28 flex items-center justify-center">
                    <Loader2 className="size-6 animate-spin" />
                  </div>
                ) : (
                  <>
                    {predictions.map((prediction) => (
                      <CommandPrimitive.Item
                        value={prediction.description}
                        onSelect={async () => {
                          setSearchInput("");
                          setIsOpenDialog(true);

                          try {
                            setIsFetching?.(true);
                            const res = await fetchPlaceDetails({
                              placeId: prediction.place_id,
                            });

                            let address1 = "";
                            let postcode = "";
                            let city = "";
                            let state = "";
                            let country = "";
                            let addressLine2 = "";

                            for (const component of res.googleMapsPlace
                              .address_components as google.maps.GeocoderAddressComponent[]) {
                              const componentType = component.types[0];
                              switch (componentType) {
                                case "street_number": {
                                  address1 = `${component.long_name} ${address1}`;
                                  break;
                                }

                                case "route": {
                                  address1 += component.short_name;
                                  break;
                                }

                                case "postal_code": {
                                  postcode = `${component.long_name}${postcode}`;
                                  break;
                                }

                                case "postal_code_suffix": {
                                  postcode = `${postcode}-${component.long_name}`;
                                  break;
                                }

                                case "locality":
                                  city = component.long_name;
                                  break;

                                case "administrative_area_level_1": {
                                  state = component.long_name;
                                  break;
                                }

                                case "country":
                                  country = component.long_name;
                                  break;

                                case "subpremise":
                                  addressLine2 = component.long_name;
                                  break;
                              }
                            }

                            setAddress?.({
                              name: prediction.description,
                              place_id: prediction.place_id,
                              address_line_1: address1,
                              address_line_2: addressLine2,
                              city,
                              state,
                              postcode,
                              country,
                              lat: res.googleMapsPlace.geometry.location.lat,
                              lng: res.googleMapsPlace.geometry.location.lng,
                            });
                          } catch (error) {
                            console.error(error);
                          } finally {
                            setIsFetching?.(false);
                          }
                        }}
                        className="flex select-text flex-col gap-0.5 text-xs h-max p-2 px-3 rounded-md aria-selected:bg-accent aria-selected:text-accent-foreground hover:bg-accent hover:text-accent-foreground items-start"
                        key={prediction.place_id}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {prediction.description}
                      </CommandPrimitive.Item>
                    ))}
                  </>
                )}

                <CommandEmpty>
                  {!isFetching && predictions.length === 0 && (
                    <div className="py-4 flex items-center justify-center">
                      {searchInput === ""
                        ? "Please enter an address"
                        : "No address found"}
                    </div>
                  )}
                </CommandEmpty>
              </CommandGroup>
            </div>
          </CommandList>
        </div>
      )}
    </Command>
  );
}

interface FormMessagesProps extends React.HTMLAttributes<HTMLDivElement> {
  messages?: string[] | string | React.ReactNode;
  type?: "error" | "success";
}

function FormMessages({
  messages,
  type = "error",
  className,
  ...props
}: FormMessagesProps) {
  if (!messages) {
    return null;
  }

  const isReactNode = React.isValidElement(messages);

  if (!isReactNode && typeof messages === "string") {
    messages = [messages];
  }

  return (
    <div
      aria-invalid={type === "error"}
      className={cn(
        "flex flex-col text-sm text-destructive",
        type === "success" && " text-muted-foreground",
        className,
      )}
      {...props}
    >
      {isReactNode
        ? messages
        : (messages as string[]).map((value, i) => (
            <div key={i.toString()} className="flex gap-2">
              {type === "error" ? (
                <XCircle className="relative top-0.5 size-4 shrink-0" />
              ) : (
                <CheckCircle2 className="relative top-0.5 size-4 shrink-0" />
              )}
              <p>{value}</p>
            </div>
          ))}
    </div>
  );
}

interface AddressDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  address?: CreateAddressInput;
  setAddress: (address: CreateAddressInput) => void;
  dialogTitle: string;
  isLoading: boolean;
  name: string;
}

export function AddressDialog({
  children,
  dialogTitle,
  open,
  setOpen,
  isLoading,
  name,
}: PropsWithChildren<AddressDialogProps>) {
  const [{ value }] = useField<CreateAddressInput>(name);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
        </DialogHeader>

        {isLoading ? (
          <div className="h-52 flex items-center justify-center">
            <Loader2 className="size-6 animate-spin" />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-12 gap-x-6">
              <Map
                className="col-span-full mb-6 overflow-hidden rounded-md"
                height={200}
                position={{
                  lat: value.lat ?? 0,
                  lng: value.lng ?? 0,
                }}
              />

              <TextInput
                name={`${name}.address_line_1`}
                label="Address line 1"
                className="col-span-12"
              />
              <TextInput
                name={`${name}.address_line_2`}
                label="Apartment, unit, suite, or floor #"
                className="col-span-12"
                optionalLabel
              />
              <TextInput
                name={`${name}.city`}
                label="City"
                className="col-span-6"
              />
              <TextInput
                name={`${name}.state`}
                label="State / Province / Region"
                className="col-span-6"
              />
              <TextInput
                name={`${name}.postcode`}
                label="Postcode"
                className="col-span-6"
              />
              <TextInput
                name={`${name}.country`}
                label="Country"
                className="col-span-6"
              />
            </div>
            <DialogFooter>
              <Button
                type="button"
                onClick={() => setOpen(false)}
                variant={"outline"}
              >
                Cancel
              </Button>
              <Button type="submit" onClick={() => setOpen(false)}>
                Save
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
