import { ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { VehicleCreateDialog } from "@/app/Vehicles/Components/VehicleCreateDialog";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { vehicleListQuery } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { QueryVehiclesOrderByColumn, SortOrder } from "@/gql/graphql";

export function VehicleRenderCell(props: ICellRendererParams<any>) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const supplierId = props.colDef?.cellRendererParams?.supplier?.id;

  const { data } = useGqlQuery(vehicleListQuery, {
    first: 200,
    page: 1,
    supplier_id: supplierId,
    orderBy: [
      {
        column: QueryVehiclesOrderByColumn.CreatedAt,
        order: SortOrder.Asc,
      },
    ],
  });

  const vehicle = data?.vehicles.data.find((vehicle) => {
    if (!cellValue || typeof cellValue !== "string") return false;

    return vehicle.code?.trim() === cellValue?.trim();
  });

  const { open } = useDialog(VehicleCreateDialog);

  const showWarning = !vehicle;

  if (!cellValue) return null;

  return (
    <div
      className={classNames("flex w-full space-x-2 items-center", {
        "bg-red-300 text-red-700": showWarning,
      })}
    >
      {showWarning ? (
        <IconButton
          className="text-red-700 text-xs p-1"
          type="button"
          onClick={() => {
            open({
              initialValues: {
                supplier: {
                  connect: supplierId,
                },
                code: cellValue,
              },
            });
          }}
          Icon={PlusIcon}
          tooltip="Create vehicle"
        />
      ) : null}
      <span>{cellValue}</span>
    </div>
  );
}
