import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { Link } from "@tanstack/react-router";
import { VehicleListItem } from "@/app/Vehicles/GraphQL/vehicleListQuery";

export function VehicleMissingCodeWarning({
  vehicles,
}: {
  vehicles: VehicleListItem[];
}) {
  const officesWithoutCodes = vehicles.filter((office) => !office.code);

  if (officesWithoutCodes.length === 0) {
    return null;
  }

  return (
    <WarningBanner>
      Some vehicles are missing codes. Please add codes to the following
      offices:
      <ul className="list-disc list-inside">
        {officesWithoutCodes.map((vehicle) => (
          <li key={vehicle.id}>
            <Link className="underline" to={`/vehicles/${vehicle.id}`}>
              {vehicle.name}
            </Link>
          </li>
        ))}
      </ul>
    </WarningBanner>
  );
}
