import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { SupplierOfficeListItem } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { Link } from "@tanstack/react-router";

export function OfficeMissingCodeWarning({
  offices,
}: {
  offices: SupplierOfficeListItem[];
}) {
  const officesWithoutCodes = offices.filter((office) => !office.code);

  if (officesWithoutCodes.length === 0) {
    return null;
  }

  return (
    <WarningBanner>
      Some offices are missing codes. Please add codes to the following offices:
      <ul className="list-disc list-inside">
        {officesWithoutCodes.map((office) => (
          <li key={office.id}>
            <Link className="underline" to={`/offices/${office.id}`}>
              {office.name}
            </Link>
          </li>
        ))}
      </ul>
    </WarningBanner>
  );
}
