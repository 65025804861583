import { Outlet } from "@tanstack/react-router";
import { Suspense } from "react";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";

export function ImportSupplierLayout() {
  return (
    <Suspense
      fallback={
        <div className="w-full h-full flex items-center justify-center">
          <Spinner className="size-4" />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
}
