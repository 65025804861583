import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { Link, useMatch, useNavigate } from "@tanstack/react-router";
import Dropzone from "react-dropzone";
import { cn } from "@/lib/utils";
import { UploadIcon } from "lucide-react";
import { useState } from "react";
import { Heading } from "@/components/catalyst/heading";
import { Button } from "@/lib/Components/Button/Button";
import { useDownload } from "@/lib/Utils/useDownload";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { generateRelocationImportTemplate } from "@/app/Import/GraphQL/generateRelocationTemplate";
import { useImportState } from "@/app/Import/Stores/useImportState";
import readXlsxFile from "read-excel-file";
import { useGetTransformFileInputToRowData } from "@/app/Import/Utils/useTransformFileInputToRowData";

export function ImportUploadScreen() {
  const { params } = useMatch({
    from: "/_app/import/$supplierId",
  });
  const navigate = useNavigate();

  const { addRelocations } = useImportState();

  const downloadFile = useDownload();
  const { mutateAsync } = useGqlMutation(generateRelocationImportTemplate);
  const transformData = useGetTransformFileInputToRowData(params.supplierId);

  const [isDisabled] = useState(false);

  return (
    <FullPageScreen>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10 mb-6">
        <Heading>Import Relocations</Heading>
        <div className="flex gap-4">
          <Button
            onClick={async () => {
              const data = await mutateAsync({
                supplierId: params.supplierId,
              });

              downloadFile(data.generateImportTemplate, `imoova_template.xlsx`);
            }}
          >
            Download template
          </Button>
        </div>
      </div>

      <Dropzone
        accept={{
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
          "application/vnd.ms-excel": [],
          "text/csv": [],
        }}
        onDrop={async (acceptedFiles) => {
          const file = acceptedFiles.at(0);
          if (!file) {
            alert("No file uploaded");
            return;
          }

          const rows = await readXlsxFile(file);

          addRelocations(transformData(rows));

          navigate({
            to: "/import/$supplierId/spreadsheet",
            params: {
              supplierId: params.supplierId,
            },
          });
        }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            className={cn(
              "group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25",
              "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
              isDragActive && "border-muted-foreground/50",
              isDisabled && "pointer-events-none opacity-60",
            )}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                <div className="rounded-full border border-dashed p-3">
                  <UploadIcon
                    className="size-7 text-muted-foreground"
                    aria-hidden="true"
                  />
                </div>
                <p className="font-medium text-muted-foreground">
                  Drop the files here
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                <div className="rounded-full border border-dashed p-3">
                  <UploadIcon
                    className="size-7 text-muted-foreground"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex flex-col gap-px">
                  <p className="font-medium text-muted-foreground">
                    Drag {`'n'`} drop files here, or click to select files
                  </p>
                  <p className="text-sm text-muted-foreground/70">
                    You can upload xlsx, xls, or csv files
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </Dropzone>

      <p className="text-center mt-2">
        <Link
          className="underline text-blue-400 text-xs"
          to="/import/$supplierId/spreadsheet"
          params={{
            supplierId: params.supplierId,
          }}
        >
          Continue without uploading &rarr;
        </Link>
      </p>
    </FullPageScreen>
  );
}
