import { ICellRendererParams } from "ag-grid-community";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { Currency } from "@/gql/graphql";
import { cn } from "@/lib/utils";

export function CurrencyRenderCell(props: ICellRendererParams) {
  const cellValue = props.value;
  const currency: Currency = props.colDef?.cellRendererParams?.currency;

  if (!currency) return "ERR";

  if (!cellValue || !Number.isInteger(cellValue)) return null;

  //Show a warning if we have a value greater than $1000
  //This might be an issue with JPY
  const showWarning = cellValue > 1000;

  return (
    <span
      className={cn("flex w-full justify-end text-right", {
        "bg-yellow-300 text-yellow-700": showWarning,
      })}
    >
      {formatCurrency(cellValue * 100, currency)}
    </span>
  );
}
