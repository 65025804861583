import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@/components/catalyst/dropdown";
import { Avatar } from "@/components/catalyst/avatar";
import { ArrowLeftRightIcon } from "lucide-react";
import { useNavigate } from "@tanstack/react-router";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useClerk, useOrganization } from "@clerk/clerk-react";
import { getInitials } from "@/lib/Utils/getInitials";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { SidebarItemButton } from "@/components/catalyst/sidebar";
import {
  ChevronDownIcon,
  Cog6ToothIcon,
  RectangleGroupIcon,
} from "@heroicons/react/16/solid";

export function SidebarHeaderDropdown() {
  const {
    supplier,
    supplierList,
    actions: { setSupplier },
  } = useGlobalSupplier();
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const { isAdmin } = useTenant();
  const { openOrganizationProfile } = useClerk();

  return (
    <Dropdown>
      <DropdownButton as={SidebarItemButton}>
        <div
          className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-w truncate"
          aria-current="page"
        >
          <div className="flex items-center flex-grow">
            {supplier ? (
              <>
                <Avatar
                  media={supplier?.logo}
                  initials={getInitials(supplier.name)}
                  className="mr-4 h-6 w-6 flex-shrink-0 bg-white"
                />
                <div className="flex-grow truncate">{supplier?.name}</div>
              </>
            ) : (
              <div className="flex items-center">
                <img
                  src={organization?.imageUrl}
                  alt={organization?.name}
                  className="mr-4 h-6 w-6 flex-shrink-0 bg-white rounded-full object-contain"
                />
                <span className="flex-grow">{organization?.name}</span>
              </div>
            )}
          </div>
        </div>
        <ChevronDownIcon />
      </DropdownButton>
      <DropdownMenu className="min-w-64" anchor="bottom start">
        <DropdownItem
          onClick={() => {
            openOrganizationProfile();
          }}
        >
          <Cog6ToothIcon />
          <DropdownLabel>Organisation settings</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        {supplierList.map((supplier) => (
          <DropdownItem
            key={supplier.id}
            onClick={() => {
              setSupplier(supplier.id);
            }}
          >
            <Avatar
              media={supplier.logo}
              initials={getInitials(supplier.name)}
              className="bg-purple-500 text-white"
            />
            <DropdownLabel>{supplier.name}</DropdownLabel>
          </DropdownItem>
        ))}

        <DropdownDivider />
        {supplierList.length > 1 || isAdmin ? (
          <>
            <DropdownItem
              onClick={() => {
                setSupplier(null);
              }}
            >
              <RectangleGroupIcon />
              <DropdownLabel>All suppliers</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />
          </>
        ) : null}

        <DropdownItem
          onClick={() => {
            navigate({
              to: "/change-organisation",
            });
          }}
        >
          <ArrowLeftRightIcon className="w-4 h-4" />
          <DropdownLabel>Change organisation</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
