import { create } from "zustand";
import { RelocationItemInput } from "@/gql/graphql";

type ImportState = {
  relocations: RelocationItemInput[];
  addRelocations: (relocations: RelocationItemInput[]) => void;
  reset: () => void;
};

const initialState = Array.from({
  length: 100,
}).map(() => ({})) as RelocationItemInput[];

export const useImportState = create<ImportState>()((set) => ({
  relocations: initialState,
  addRelocations: (relocations) => set(() => ({ relocations })),
  reset: () => set(() => ({ relocations: initialState })),
}));
