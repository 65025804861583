import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useTranslation } from "react-i18next";
import { invoiceRoute } from "@/app/Invoices/Routes/invoiceRoutes";
import { useState } from "react";
import { invoiceStatusIntentMap } from "../Utils/invoiceStatusIntentMap";
import { SidebarList } from "@/lib/Components/SideBarList/SideBarList";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  InvoiceListItem,
  invoiceListQuery,
} from "@/app/Invoices/GraphQL/invoiceListQuery";
import { getInvoiceListQueryVariables } from "@/app/Invoices/Utils/getInvoiceListQueryVariables";
import { keepPreviousData } from "@tanstack/react-query";
import { Badge } from "@/components/catalyst/badge";
import { useThrottle } from "@/lib/Utils/useThrottle";

export function InvoiceListScreen() {
  const [search, setSearch] = useState("");
  const throttleSearch = useThrottle(search, 500);

  const { data, fetchNextPage, isFetching, isFetchingNextPage } =
    useInfiniteGqlQuery(
      invoiceListQuery,
      getInvoiceListQueryVariables({}, throttleSearch),
      {
        placeholderData: keepPreviousData,
        initialPageParam: {
          page: 1,
        },
        getNextPageParam: (lastPage) => {
          return lastPage.invoices.paginatorInfo.hasMorePages
            ? {
                page: lastPage.invoices.paginatorInfo.currentPage + 1,
              }
            : undefined;
        },
      },
    );

  const items = data?.pages.flatMap((p) => p.invoices.data);
  const totalCount = data?.pages.at(0)?.invoices.paginatorInfo.total ?? 0;

  return (
    <SidebarList<InvoiceListItem>
      items={items}
      totalCount={totalCount}
      linkProps={(item) => ({
        to: invoiceRoute.to,
        params: {
          id: item.id,
        },
      })}
      search={search}
      onSearchChange={(newStr) => {
        setSearch(newStr);
      }}
      onClearFilters={() => {
        setSearch("");
      }}
      listRoute={"/invoices"}
      createRoute={"/invoices/create"}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isFetching={isFetching}
      itemNode={(item) => <Node model={item} />}
    />
  );
}

function Node({ model }: { model: InvoiceListItem }) {
  const { t } = useTranslation("invoice");

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <p className="text-sm font-medium text-gray-900">{model.reference}</p>
        <p className="text-sm text-gray-500">{model.payee.name}</p>
        <p>
          <Badge color={invoiceStatusIntentMap[model.status]}>
            {t(`status.${model.status}`)}
          </Badge>
        </p>
        <p className="text-xs text-gray-500">
          {formatDateTimeSinceNow(model.created_at)}
        </p>
      </div>
    </div>
  );
}
