import {
  SidebarList,
  SidebarListItemProps,
} from "@/lib/Components/SideBarList/SideBarList";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { SupplierIcon } from "@/app/Suppliers/Components/SupplierIcon";
import { MailIcon } from "lucide-react";
import { suppliersRoute } from "@/app/Suppliers/Routes/supplierRoutes";
import { useState } from "react";
import { useSearch } from "@tanstack/react-router";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { getSupplierListQueryVariables } from "@/app/Suppliers/Utils/getSupplierListQueryVariables";
import {
  SupplierListItem,
  supplierListQuery,
} from "@/app/Suppliers/GraphQL/supplierListQuery";

export function SupplierListScreen() {
  const searchParams = useSearch({
    from: suppliersRoute.id,
  });
  const [search, setSearch] = useState("");

  const { data, fetchNextPage, isFetching, isFetchingNextPage } =
    useInfiniteGqlQuery(
      supplierListQuery,
      {
        ...getSupplierListQueryVariables(searchParams, search),
      },
      {
        getNextPageParam: (lastPage) => {
          const paginatorInfo = lastPage.suppliers.paginatorInfo;
          if (paginatorInfo.hasMorePages) {
            return {
              page: paginatorInfo.currentPage + 1,
            };
          }

          return undefined;
        },
        initialPageParam: {
          page: 1,
        },
      },
    );

  const items = data?.pages.flatMap((i) => i.suppliers.data);
  const total = data?.pages.at(0)?.suppliers.paginatorInfo.total ?? 0;

  return (
    <SidebarList<SupplierListItem>
      onClearFilters={() => {
        setSearch("");
      }}
      items={items}
      listRoute={"/suppliers"}
      search={search}
      onSearchChange={setSearch}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      totalCount={total}
      itemNode={(i) => <SupplierItem model={i} />}
      linkProps={(i) => ({
        to: "/suppliers/$id",
        params: {
          id: i.id,
        },
      })}
    />
  );
}

function SupplierItem({ model }: SidebarListItemProps<SupplierListItem>) {
  return (
    <div className="flex items-center space-x-3">
      <div className="">
        <SupplierIcon model={model} className="size-8" />
      </div>
      <div className="min-w-0 flex-1">
        <p className="truncate text-sm font-medium text-gray-900">
          {model.name}
        </p>
        {model.email ? (
          <p className="truncate text-xs text-gray-500 flex items-center">
            <MailIcon className="w-4 h-4 inline mr-2 flex-shrink-0" />
            {model.email}
          </p>
        ) : null}
        <p>
          <time dateTime={model.created_at} className="text-xs text-gray-500">
            {formatDateTimeSinceNow(model.created_at)}
          </time>
        </p>
      </div>
    </div>
  );
}
