import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { globalSupplierSelectionList } from "@/app/Suppliers/GraphQL/globalSupplierSelectionList";
import { ReactNode, useState } from "react";
import { ResultOf } from "@graphql-typed-document-node/core";
import { createStore } from "zustand";
import { SupplierContext } from "./GlobalSupplierContext";
import { useLocalStorage } from "react-use";
import { localStorageKeys } from "@/lib/utils";
import { SupplierMissingScreen } from "@/app/Suppliers/Screens/SupplierMissingScreen";
import { useRouter } from "@tanstack/react-router";

export type GlobalSupplier = ResultOf<
  typeof globalSupplierSelectionList
>["suppliers"]["data"][number];

export type GlobalSupplierContext = {
  supplierId: string | null;
  hasSupplier: boolean;
  supplierList: GlobalSupplier[];
  supplierCount: number;
  actions: {
    setSupplier: (supplierId: string | null) => void;
  };
};

export type GlobalSupplierStorageObject =
  | {
      type: "single";
      id: string;
    }
  | {
      type: "all";
      id: undefined;
    };

export function GlobalSupplierProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const { data } = useSuspenseGqlQuery(globalSupplierSelectionList, {
    page: 1,
    first: 200,
  });

  const defaultSupplierId =
    data.suppliers.data.length === 1 ? data.suppliers.data[0].id : undefined;

  const defaultObject: GlobalSupplierStorageObject = defaultSupplierId
    ? { type: "single", id: defaultSupplierId }
    : { type: "all", id: undefined };

  const [globalSupplier = defaultObject, setGlobalSupplier] =
    useLocalStorage<GlobalSupplierStorageObject>(
      localStorageKeys.GLOBAL_SUPPLIER,
      defaultObject,
    );

  const [store] = useState(() =>
    createStore<GlobalSupplierContext>((set) => ({
      supplierId: globalSupplier.type === "all" ? null : globalSupplier.id,
      hasSupplier: globalSupplier.type === "single",
      supplierList: data.suppliers.data ?? [],
      supplierCount: data.suppliers.paginatorInfo.total,
      actions: {
        setSupplier: (supplierId: string | null) => {
          set(() => ({ supplierId, hasSupplier: !!supplierId }));
          if (!supplierId) {
            setGlobalSupplier({
              type: "all",
              id: undefined,
            });
          } else {
            setGlobalSupplier({
              type: "single",
              id: supplierId,
            });
          }

          return router.navigate({
            to: "/",
          });
        },
      },
    })),
  );

  return (
    <SupplierContext.Provider value={store}>
      {data.suppliers.data.length === 0 ? <SupplierMissingScreen /> : children}
    </SupplierContext.Provider>
  );
}
