import { TableId } from "@/app/Common/Utils/tableIds";
import { useWaitlistActions } from "@/app/Waitlists/Actions/useWaitlistActions";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import {
  WaitlistListItem,
  waitlistListQuery,
} from "@/app/Waitlists/GraphQL/waitlistListQuery";
import { formatDate } from "@/lib/Formatters/formatDate";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { WaitlistListQueryVariables } from "@/gql/graphql";
import { CellContext } from "@tanstack/react-table";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { UserActivityDialog } from "@/app/Users/Components/UserActivityDialog";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { PhoneIcon } from "lucide-react";
import { UserProfilePicture } from "@/app/Users/Components/UserProfilePicture";

type WaitlistColumnId =
  | "customer"
  | "trip"
  | "dates"
  | "matches"
  | "last_called"
  | "created";

type GetWaitlistTableVariables = ({
  pagination,
  sorting,
  search,
}: {
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  sorting: {
    desc: boolean;
    id: WaitlistColumnId;
  }[];
  search: string;
}) => WaitlistListQueryVariables;

export function WaitlistTable({
  id,
  getQueryVariables,
  searchable,
}: {
  id: TableId;
  getQueryVariables: GetWaitlistTableVariables;
  searchable?: boolean;
}) {
  const getActions = useWaitlistActions();

  const columns: DataTableColDef<WaitlistListItem, any, WaitlistColumnId>[] = [
    {
      id: "customer",
      header: "Customer",
      cell: CustomerCell,
    },
    {
      id: "trip",
      header: "Trip",
      accessorFn: (row) =>
        `${row.departureCity.name} to ${row.deliveryCity.name}`,
    },
    {
      id: "dates",
      header: "Dates",
      accessorFn: (row) =>
        `${formatDate(row.earliest_departure_date)} - ${formatDate(
          row.latest_departure_date,
        )}`,
    },
    {
      id: "matches",
      header: "Matches",
      cell: MatchesCell,
    },
    {
      id: "last_called",
      header: "Last called",
      cell: LastCalledCell,
    },
    {
      id: "created",
      header: "Created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
    },
  ];

  return (
    <DataTable<
      ResultOf<typeof waitlistListQuery>,
      VariablesOf<typeof waitlistListQuery>,
      WaitlistColumnId,
      WaitlistListItem,
      any
    >
      getActions={getActions}
      id={id}
      document={waitlistListQuery}
      columns={columns}
      accessor={(data) => data.waitlists}
      getQueryVariables={getQueryVariables}
      searchable={searchable}
    />
  );
}

export function MatchesCell({ row }: CellContext<any, any>) {
  if (row.original.matches === 0) {
    return <div>--</div>;
  }

  return (
    <div>
      <a
        href={row.original.relocationLink}
        target="_blank"
        className="underline text-blue-500 flex space-x-2 items-center"
        rel="noreferrer"
      >
        <span>{row.original.matches}</span>
        <ArrowTopRightOnSquareIcon className="w-4 h-4" />
      </a>
    </div>
  );
}

function LastCalledCell({ row }: CellContext<WaitlistListItem, any>) {
  const waitlist = row.original;

  const { open } = useDialog(UserActivityDialog);

  return (
    <div className="flex items-center space-x-2">
      <span>
        {formatDateTimeSinceNow(waitlist.user.latestCallActivity?.created_at)}
      </span>
      <TextButton
        intent="primary"
        Icon={PhoneIcon}
        onClick={() => {
          open({
            userId: waitlist.user.id,
          });
        }}
      >
        Log call
      </TextButton>
    </div>
  );
}

function CustomerCell({ row }: CellContext<WaitlistListItem, any>) {
  const user = row.original.user;

  return (
    <div className="flex space-x-3 items-center">
      <UserProfilePicture model={user} />
      <div>
        <p>{user.name?.trim() || "--"}</p>
        <p>
          {user.primary_phone ? (
            <a
              className="underline text-blue-500"
              href={`tel:${user.primary_phone}`}
            >
              {user.primary_phone}
            </a>
          ) : (
            "--"
          )}
        </p>
        <p>
          {user.primary_email ? (
            <a
              className="underline text-blue-500"
              href={`mailto:${user.primary_email}`}
            >
              {user.primary_email}
            </a>
          ) : (
            "--"
          )}
        </p>
      </div>
    </div>
  );
}
