import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { GenericField, InputProps } from "../Fields/GenericField";
import {
  DATE_TIME_LOCAL_FORMAT,
  DATE_TIME_TZ_FORMAT,
  toDateTimeString,
} from "@/lib/Formatters/toDateTimeString";
import { useField } from "formik";
import dayjs from "dayjs";
import { ComponentProps } from "react";

export type TextInputProps = InputProps & {
  inputProps?: ComponentProps<"input">;
  type?: "email" | "datetime-local" | "datetime" | "text" | "time";
  onChange?: (val: string | null) => void;
};

export function TextInput({
  inputProps,
  onChange,
  type = "text",
  ...props
}: TextInputProps) {
  const { emptyValue = "" } = props;
  const [field, meta, { setValue }] = useField(props.name);
  const showError = !!meta.error && meta.touched;

  let value: string | null | number;
  switch (type) {
    case "datetime-local":
      value = field.value
        ? dayjs(field.value).local().format(DATE_TIME_LOCAL_FORMAT)
        : "";
      break;
    default:
      value = field.value ?? emptyValue;
      break;
  }

  return (
    <GenericField viewNode={<span>{value}</span>} {...props}>
      <>
        <input
          {...field}
          {...inputProps}
          id={field.name}
          type={type}
          value={value as string}
          onChange={async (event) => {
            let fieldValue: string | null;

            if (type === "datetime") {
              if (event.target.value === "") {
                fieldValue = null;
              } else {
                fieldValue = toDateTimeString(event.target.value);
              }
            } else if (type === "datetime-local") {
              if (event.target.value === "") {
                fieldValue = null;
              } else {
                fieldValue = dayjs(event.target.value)
                  .utc()
                  .format(DATE_TIME_TZ_FORMAT);
              }
            } else {
              fieldValue = event.target.value ? event.target.value : emptyValue;
            }

            await setValue(fieldValue);
            onChange?.(fieldValue);
          }}
          className={classNames(
            showError
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "focus:border-indigo-500 focus:ring-indigo-500",
            "block w-full rounded-md border-gray-300 text-sm shadow-sm transition-shadow duration-100 h-[38px] border",
          )}
        />
        {showError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </>
    </GenericField>
  );
}
