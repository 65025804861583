import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateImportSettingsMutation } from "@/app/Import/GraphQL/updateImportSettingsMutation";
import { GridState } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useThrottle } from "@/lib/Utils/useThrottle";

export function useUpdateImportSettings(supplierId: string) {
  const { mutate } = useGqlMutation(updateImportSettingsMutation, {
    onSuccess: () => {
      //Do nothing, don't invalidate any queries
      return;
    },
  });

  const [data, setData] = useState<GridState | null>(null);
  const throttled = useThrottle(data, 1000);

  useEffect(() => {
    if (throttled === null) {
      return;
    }

    mutate({
      input: {
        supplier_id: supplierId,
        grid_state: data,
      },
    });
  }, [JSON.stringify(throttled)]);

  return setData;
}
